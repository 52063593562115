@import '../theme-bootstrap';

.responsive-tabs--how_to_use-wrap {
  @include wrap;
  margin: 20px auto 0 auto;
  padding: 0;
  border-bottom: 1px solid $color-gray-light;
  @include breakpoint($tablet) {
    margin: 20px auto;
  }
}

.how-to-use {
  &__content {
    @include main-font-book;
    font-size: 11px;
    letter-spacing: 0;
    @include breakpoint($small-down) {
      padding: 15px 20px 15px 20px;
    }
    @include breakpoint($medium-up) {
      text-align: center;
      font-size: 13px;
    }
  }
  &__price {
    margin-top: 10px;
  }
  &__size-text {
    display: inline;
    font-family: $main-font;
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
  }
  &__title {
    font-size: 14px;
    letter-spacing: 0;
    text-transform: uppercase;
    @include breakpoint($small-down) {
      @include swap_direction(padding, 10px 30px 10px 20px);
      font-weight: 500;
      font-size: 12px;
    }
  }
  &__wrap {
    .outer-wrap .view-details-button {
      &::before {
        @include breakpoint($small-down) {
          font-size: 12px;
          font-weight: 700;
          #{$rdirection}: 20px;
        }
      }
    }
  }
}
